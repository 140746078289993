import React from 'react'
import styles from './LatestNews.module.scss'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { formatDateString } from '@src/utils'
import { useGlobalData } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { LongArrowRight } from '@src/assets'

export default ({ title, link }) => {
  const { latestIsNews, latestEnNews } = useGlobalData()
  const { t, language } = useLanguage()
  const newsData = language === 'is' ? latestIsNews : latestEnNews

  return (
    <FancyShow>
      <Container className={styles.container}>
        <Row>
          <Col col={10} offset={{ lg: 1 }} className='pl-md-0'>
            <h3>{title}</h3>
          </Col>
        </Row>
        <Row className={cn('mt-2 mb-3')}>
          {newsData && newsData.map(({
            url,
            pageTitle,
            date,
            key
          }) => (
            <Col
              key={key}
              col={{ md: 10 }}
              offset={{ lg: 1 }}
              className={styles.row}
            >
              <Row className='align-items-center'>
                <Col col={{ lg: 2 }} className={styles.negativeMargin}>
                  <p className='parag-big mb-1 mb-md-0'>{formatDateString(date)}</p>
                </Col>
                <Col col={{ lg: 8 }}>
                  <Link
                    to={url}
                    className={cn(styles.link, 'parag-big mb-0')}
                  >
                    {pageTitle}
                  </Link>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
        <Row>
          <Col
            col={11}
            className='d-flex justify-content-end p-0'
          >
            <Link
              className={cn(
                styles.bottomLink,
                'link link--big'
              )}
              to={link}
            >
              {t(`buttons|see all news label`)}
              <LongArrowRight />
            </Link>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
